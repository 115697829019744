import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/authStore.js";
import { jwtDecode } from "jwt-decode";
import DefaultComponent from "@/components/Layout/DefaultComponent.vue";
import LoginComponent from "@/components/Login/LoginComponent.vue";
import ValidateComponent from "@/Pages/ValidateGuest/ValidateComponent.vue";
import LocalStorage from "@/services/LocalStorage.js";
import Landing from "@/views/Landing.vue";
import Utils from "@/resources/utils.js";
import axios from "axios";

const routes = [
  {
    path: "/",
    component: Landing,
  },
  {
    path: "/token/:token",
    component: Landing,
  },
  {
    path: "/login",
    component: LoginComponent,
    name: 'login'
  },
  {
    path: "/valida-qr-code/:token",
    component: ValidateComponent,
    name: 'valida-qrcode'
  },
  {
    path: '/sistema',
    component: DefaultComponent,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        meta: {
          title: 'Dashboard',
          requiresAuth: true,
          breadcrumb: null
        },
        path: '/sistema/dashboard',
        name: 'dashboard',
        component: () => import('@/components/Dashboard/DashboardComponent.vue')
      },
      {
        meta: {
          title: 'Painel de Festas',
          requiresAuth: true,
          breadcrumb: null
        },
        path: '/sistema/festas',
        name: 'festas',
        component: () => import('@/Pages/Parties/MainView.vue')
      },
      {
        meta: {
          title: 'Lista de Convidados',
          requiresAuth: true,
          breadcrumb: null
        },
        path: "/sistema/lista-de-convidados",
        name: 'lista-convidados',
        component: () => import('@/Pages/PartyGuests/MainView.vue')
      },
    ],
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
});

router.beforeEach((to, from, next) => {
  const token = JSON.parse(LocalStorage.get('token_brota_api'))
  const authStore = useAuthStore()
  // Verifica se o token está presente e a rota requer autenticação
  if (to.meta.requiresAuth && !token) {
    // Se o token não estiver presente e a rota requer autenticação,
    // redirecione para a página de login
    next('/login');
  } else {
    // Defina o token no cabeçalho de autorização, se estiver presente
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      const decodedToken = jwtDecode(token)
      const expirationDate = new Date(decodedToken.exp * 1000)
      const currentDateTime = new Date();
      const timeUntilExpiration = expirationDate - currentDateTime;

      if (timeUntilExpiration < 5 * 60 * 1000) {
        authStore.refreshToken()
          .then(response => {
            let token = response.data.authorization.token;
            LocalStorage.set('token_brota_api', JSON.stringify(token))
          })
      }

      if (Utils.isEmpty(authStore.user)) {
        authStore.getUserLogged()
          .then(response => {
            authStore.setAuthenticated(true)
            authStore.user = response.data.user
          })
      }
    }

    next(); // Continue para a próxima rota
  }
})

export default router;