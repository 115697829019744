<template>
  <!-- ===== Page Wrapper Start ===== -->
  <div class="flex h-screen overflow-hidden">
    <!-- ===== Sidebar Start ===== -->
    <SidebarArea />
    <!-- ===== Sidebar End ===== -->

    <!-- ===== Content Area Start ===== -->
    <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
      <!-- ===== Header Start ===== -->
      <HeaderArea />
      <!-- ===== Header End ===== -->

      <!-- ===== Main Content Start ===== -->
      <main>
        <div class="mx-auto w-full p-4 md:p-6 2xl:p-10">
          <BreadcrumbDefault :page-title="route.meta.title" />
          <router-view />
          <button data-modal-target="modal-is-first-access" class="hidden"></button>
          <ModalComponent :has-button-close="false" id="modal-is-first-access" header="Alterar a Senha">
            <template #content>
              <FormPassword @changed-password="changedPassword"/>
            </template>
          </ModalComponent>
        </div>
      </main>
      <!-- ===== Main Content End ===== -->
    </div>
  </div>
  <!-- ===== Page Wrapper End ===== -->
</template>
<script setup>
import { initModals, Modal } from "flowbite";
import { useAuthStore } from "@/stores/authStore.js";
import {onMounted, onUpdated} from "vue";
import { useBracelets } from "@/stores/braceletsStore.js";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import BreadcrumbDefault from "@/components/Breadcrumbs/BreadcrumbDefault.vue";
import SidebarArea from '@/components/Sidebar/SidebarArea.vue'
import HeaderArea from '@/components/Header/HeaderArea.vue'
import ModalComponent from "@/components/Layout/ModalComponent.vue";
import FormPassword from "@/components/Users/FormPassword.vue";

const route = useRoute();
const authUser = useAuthStore();
const toast = useToast();
const braceletsStore = useBracelets();

onMounted(() => {
  checkFirstAccess();
  braceletsStore.getBracelets()
    .then(response => {
      braceletsStore.setBracelets(response.data.bracelets);
    })
});

onUpdated(() => {
  checkFirstAccess();
})

const checkFirstAccess = () => {
  if (authUser.user.is_first_access === 1) {
    initModals();
    const target = document.getElementById('modal-is-first-access');
    const modalOptions = {
      closable: false,
      backdrop: "static",
    };
    const instanceOptions = {
      id: 'modal-is-first-access',
      override: true
    };
    const modal = new Modal(target, modalOptions, instanceOptions);
    modal.show();
  }
}

const changedPassword = success => {
  if (success) {
    toast.success('Senha alterada com sucesso');
  } else {
    toast.error('Ocorreu um erro ao tentar salvar sua senha');
  }
}
</script>