import { defineStore, acceptHMRUpdate } from "pinia"
import axios from 'axios'

export const usePartyGuests = defineStore({
  id: 'party-guests',
  state: () => ({
    isLoadingPartyGuests: false,
    partyGuest: {
      id: '',
      party_id: '',
      full_name: '',
      email: '',
      cpf: '',
      instagram: '',
      status_guest: '',
      who_invited_id: '',
      verified_profile: '',
      bracelets: '',
    },
    partyGuests: []
  }),
  actions: {
    async saveGuest() {
      this.isLoadingPartyGuests = true;
      try {
        return await axios.post('/save-guest', {
          guest: this.partyGuest,
        })
      } catch (error) {
        throw error;
      } finally {
        this.isLoadingPartyGuests = false;
      }
    },
    async getPartyGuests() {
      this.isLoadingPartyGuests = true;
      try {
        return await axios.get('/get-party-guests');
      } catch (error) {
        throw error;
      } finally {
        this.isLoadingPartyGuests = false;
      }
    },
    async confirmGuest(token) {
      this.isLoadingPartyGuests = true;
      try {
        return await axios.get(`/token/${token}`);
      } catch (error) {
        throw error;
      } finally {
        this.isLoadingPartyGuests = false;
      }
    },
    async checkEmail() {
      this.isLoadingPartyGuests = true;
      try {
        return await axios.post(`/check-email`, {
          email: this.partyGuest.email
        });
      } catch (error) {
        throw error;
      } finally {
        this.isLoadingPartyGuests = false;
      }
    },
    async resendEmailGuest(id_guest) {
      this.isLoadingPartyGuests = true;
      try {
        return await axios.post(`/party-guests/resend-email`, {
          id: id_guest
        });
      } catch (error) {
        throw error;
      } finally {
        this.isLoadingPartyGuests = false;
      }
    },
    async validateGuestInParty(id_guest) {
      this.isLoadingPartyGuests = true;
      try {
        return await axios.post(`/party-guests/validate-guest-in-party`, {
          id: id_guest
        });
      } catch (error) {
        throw error;
      } finally {
        this.isLoadingPartyGuests = false;
      }
    },
    async checkQrCode(token) {
      this.isLoadingPartyGuests = true;
      try {
        return await axios.get(`/check-qr-code/${token}`);
      } catch (error) {
        throw error;
      } finally {
        this.isLoadingPartyGuests = false;
      }
    },
    async setBraceletPartyGuest(id, color) {
      this.isLoadingPartyGuests = true;
      try {
        return await axios.post(`/party-guests/set-bracelet`, {
          id,
          color
        });
      } catch (error) {
        throw error;
      } finally {
        this.isLoadingPartyGuests = false;
      }
    },
    async deletePartyGuest(id) {
      this.isLoadingPartyGuests = true;
      try {
        return axios.delete(`/party-guests/delete/${id}`);
      } catch (error) {
        throw error;
      } finally {
        this.isLoadingPartyGuests = false;
      }
    },
    async doDownloadList() {
      this.isLoadingPartyGuests = true;
      try {
        return axios.get('/party-guests/download-list/')
      } catch (error) {
        throw error;
      } finally {
        this.isLoadingPartyGuests = false;
      }
    },
    setPartyGuests(party_guests) {
      this.partyGuests = party_guests;
    },
    deletePartyGuestFromArray(id) {
      this.partyGuests = this.partyGuests.filter(item => item.id !== id);
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePartyGuests, import.meta.hot))
}