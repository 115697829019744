<template>
<!--  <div class="px-10 pt-5 text-slate-700">-->
<!--    Siga essas etapas para comparecer ao Evento:-->
<!--    <ul class="mt-5">-->
<!--      <li>1 - Siga Nossa Página no Instagram.</li>-->
<!--      <li>2 - Confira o Email enviado depois do cadastro e confirme seu email.</li>-->
<!--    </ul>-->
<!--  </div>-->
<!--  <Form :validation-schema="validationSchema"-->
<!--        @submit="saveGuest"-->
<!--        v-slot="{ errors }">-->
<!--    <div class="flex-auto p-5 lg:p-10">-->
<!--      <div class="relative w-full mb-3">-->
<!--        <label-->
<!--            class="block uppercase text-gray-700 text-xs font-bold mb-2"-->
<!--            for="full-name"-->
<!--        >Nome Completo</label-->
<!--        ><Field-->
<!--          :class="{'bg-red-50 border border-red-500 text-red-900 focus:ring-red-500 focus:border-red-500' : errors.fullName, 'border-0 placeholder-gray-400 text-gray-700 bg-white': !errors.fullName}"-->
<!--          class="px-3 py-3 w-full shadow rounded text-sm focus:outline-none focus:ring"-->
<!--          placeholder="Seu Nome"-->
<!--          v-model="partyGuestsStore.partyGuest.full_name"-->
<!--          name="fullName"-->
<!--          id="full-name"-->
<!--          style="transition: all 0.15s ease 0s;"-->
<!--      />-->
<!--        <ErrorMessage name="fullName" class="text-red-600"/>-->
<!--      </div>-->
<!--      <div class="relative w-full mb-3">-->
<!--        <label-->
<!--            class="block uppercase text-gray-700 text-xs font-bold mb-2"-->
<!--            for="email"-->
<!--        >Email</label-->
<!--        ><Field-->
<!--          name="email"-->
<!--          id="email"-->
<!--          v-model="partyGuestsStore.partyGuest.email"-->
<!--          @blur="checkEmail"-->
<!--          :class="{'bg-red-50 border border-red-500 text-red-900 focus:ring-red-500 focus:border-red-500' : errors.email, 'border-0 placeholder-gray-400 text-gray-700 bg-white': !errors.email}"-->
<!--          class="px-3 py-3 w-full shadow rounded text-sm focus:outline-none focus:ring"-->
<!--          placeholder="Seu Email"-->
<!--          style="transition: all 0.15s ease 0s;"-->
<!--      />-->
<!--        <ErrorMessage name="email" class="text-red-600"/>-->
<!--      </div>-->
<!--      <div class="relative w-full mb-3">-->
<!--        <label-->
<!--            class="block uppercase text-gray-700 text-xs font-bold mb-2"-->
<!--            for="cpf"-->
<!--        >CPF</label-->
<!--        ><Field-->
<!--          name="cpf"-->
<!--          v-maska="'###.###.###-##'"-->
<!--          id="cpf"-->
<!--          v-model="partyGuestsStore.partyGuest.cpf"-->
<!--          @blur="checkCpf"-->
<!--          :class="{'bg-red-50 border border-red-500 text-red-900 focus:ring-red-500 focus:border-red-500' : errors.cpf, 'border-0 placeholder-gray-400 text-gray-700 bg-white': !errors.cpf}"-->
<!--          class="px-3 py-3 w-full shadow rounded text-sm focus:outline-none focus:ring"-->
<!--          placeholder="Seu CPF"-->
<!--          style="transition: all 0.15s ease 0s;"-->
<!--      />-->
<!--        <ErrorMessage name="cpf" class="text-red-600"/>-->
<!--      </div>-->
<!--      <div class="relative w-full mb-3">-->
<!--        <label-->
<!--            class="block uppercase text-gray-700 text-xs font-bold mb-2"-->
<!--            for="instagram"-->
<!--        >Instagram</label-->
<!--        ><Field-->
<!--          :class="{'bg-red-50 border border-red-500 text-red-900 focus:ring-red-500 focus:border-red-500' : errors.instagram, 'border-0 placeholder-gray-400 text-gray-700 bg-white': !errors.instagram}"-->
<!--          class="px-3 py-3 w-full shadow rounded text-sm focus:outline-none focus:ring"-->
<!--          placeholder="Seu Instagram"-->
<!--          v-model="partyGuestsStore.partyGuest.instagram"-->
<!--          name="instagram"-->
<!--          id="instagram"-->
<!--          style="transition: all 0.15s ease 0s;"-->
<!--      />-->
<!--        <ErrorMessage name="instagram" class="text-red-600"/>-->
<!--      </div>-->
<!--      <div class="relative w-full mb-3">-->
<!--        <label-->
<!--            class="block uppercase text-gray-700 text-xs font-bold mb-2"-->
<!--            for="who-invited-id"-->
<!--        >Como ficou Sabendo?</label-->
<!--        ><Field-->
<!--          as="select"-->
<!--          :class="{'bg-red-50 border border-red-500 text-red-900 focus:ring-red-500 focus:border-red-500' : errors.who_invited_id, 'border-0 placeholder-gray-400 text-gray-700 bg-white': !errors.who_invited_id}"-->
<!--          class="px-3 py-3 w-full shadow rounded text-sm focus:outline-none focus:ring"-->
<!--          v-model="partyGuestsStore.partyGuest.who_invited_id"-->
<!--          name="who_invited_id"-->
<!--          id="who-invited-id"-->
<!--          style="transition: all 0.15s ease 0s;"-->
<!--      >-->
<!--        <option v-if="!whoInvitedStore.isLoadingWhoInvited" v-for="wi in whoInvitedStore.who_inviteds" :key="wi.id" :value="wi.id">{{ wi.nickname === 'instagram' ? `Fiquei sabendo pelo ${wi.name}` : `Foi ${wi.nickname} que me chamou` }}</option>-->
<!--      </Field>-->
<!--        <ErrorMessage name="who_invited_id" class="text-red-600"/>-->
<!--      </div>-->
<!--      <vue-recaptcha v-if="!isLocal" v-show="showRecaptcha" sitekey="6LdN43IqAAAAAFHha7wryxM46jda-OeoyADXv0gQ"-->
<!--                     size="normal"-->
<!--                     theme="light"-->
<!--                     hl="pt-BR"-->
<!--                     :loading-timeout="loadingTimeout"-->
<!--                     @verify="recaptchaVerified"-->
<!--                     @expire="recaptchaExpired"-->
<!--                     @fail="recaptchaFailed"-->
<!--                     @error="recaptchaError">-->
<!--      </vue-recaptcha>-->
<!--      <div class="text-center mt-6">-->
<!--        <button-->
<!--            id="btn-submit"-->
<!--            class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 transition-all duration-150 ease-in-out disabled:bg-gray-400 disabled:cursor-not-allowed disabled:shadow-none cursor-not-allowed"-->
<!--            type="submit"-->
<!--            disabled-->
<!--        >-->
<!--          Confirmar Presença-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </Form>-->
  <div class="flex items-center justify-center h-[350px] p-5">
    <p>
      Ai ai ai. O evento já rolou. Eu não queria ser o cara que traz más notícias, mas quem não foi perdeu A FES-TA. O clima foi incrível e todos curtiram da melhor maneira que podiam curtir. O próximo evento?
      Eu não sei ainda o dia, mas sei que é em <b>Fevereiro</b>. Então se eu fosse você, ficava ligado aqui no site pra saber a data do próximo.
      <br/>
      <br/>
      Equipe Brota Na Pico
    </p>
  </div>
</template>
<script setup>
import { Form, Field, ErrorMessage } from 'vee-validate';
import { onMounted, ref, toRaw } from "vue";
import { usePartyGuests } from "@/stores/partyGuestsStore.js";
import { useWhoInvited } from "@/stores/whoInvitedStore.js";
import { toTypedSchema } from "@vee-validate/zod";
import { useParties } from "@/stores/partiesStore.js";
import { useToast } from "vue-toastification";
import { vMaska } from "maska/vue"
import { z } from 'zod';
import vueRecaptcha from 'vue3-recaptcha2';
import Utils from "@/resources/utils.js";

const partyGuestsStore = usePartyGuests();
const partiesStore = useParties();
const whoInvitedStore = useWhoInvited();
const toast = useToast();
const showRecaptcha = ref(true);
const loadingTimeout = ref(300000);
const isLocal = import.meta.env.VITE_APP_ENV === 'local';

onMounted(() => {
  whoInvitedStore.getWhoInvited()
      .then(response => {
        whoInvitedStore.setWhoInviteds(response.data.who_inviteds);
      })
  if (isLocal) {
    enableButtonForm();
  }
});

const validationSchema = toTypedSchema(z.object({
  fullName: z.string({
    required_error: "Campo obrigatório",
    invalid_type_error: "Esse campo precisa ser do tipo texto",
  }).min(2, 'Tamanho inválido'),
  email: z.string({
    required_error: "Campo obrigatório",
    invalid_type_error: "Esse campo precisa ser do tipo texto",
  })
  .email('Formato de email inválido'),
  cpf: z.string({
    required_error: "Campo obrigatório",
    invalid_type_error: "Esse campo precisa ser do tipo texto",
  }).min(2, 'Tamanho inválido'),
  instagram: z.string({
    required_error: "Campo obrigatório",
    invalid_type_error: "Esse campo precisa ser do tipo texto",
  }).min(2, 'Tamanho inválido'),
  who_invited_id: z.number({
    required_error: "Campo obrigatório",
    invalid_type_error: "Campo obrigatório",
  }),
}));
const saveGuest = (values, actions) => {
  if (!checkCpf()) {
    return false;
  }

  partyGuestsStore.partyGuest.party_id = partiesStore.party.id;
  partyGuestsStore.saveGuest()
      .then(response => {
        toast.success('Tudo certo com o seu cadastro pro evento. Confira sua caixa de email',{
          timeout: 5000
        });
        actions.resetForm();
      })
    .catch(error => {
      console.error(error.message);
    });
}

const checkCpf = () => {
  if (!Utils.validaCpf(toRaw(partyGuestsStore.partyGuest.cpf))) {
    disableButtonForm();
    document.getElementById('cpf').classList.remove('border-0');
    document.getElementById('cpf').classList.remove('placeholder-gray-400');
    document.getElementById('cpf').classList.remove('text-gray-700');
    document.getElementById('cpf').classList.remove('bg-white');
    document.getElementById('cpf').classList.add('bg-red-50');
    document.getElementById('cpf').classList.add('border');
    document.getElementById('cpf').classList.add('border-red-500');
    document.getElementById('cpf').classList.add('text-red-900');
    document.getElementById('cpf').classList.add('focus:ring-red-500');
    document.getElementById('cpf').classList.add('focus:border-red-500');
    toast.error('Por favor, informe um CPF válido!', {
      timeout: 8000,
    });
    return false;
  } else {
    return true;
  }
}

const recaptchaVerified = (response) => {
  if (response) {
    enableButtonForm();
  }
}

const recaptchaExpired = () => {
  this.$refs.vueRecaptcha.reset();
}

const recaptchaFailed = () => {
  toast.error('Erro em validar o captcha');
}

const recaptchaError = (reason) => {
  toast.error(reason);
}

const enableButtonForm = () => {
  const btnSubmit = document.getElementById("btn-submit");
  btnSubmit.removeAttribute("disabled");
  btnSubmit.classList.remove('disabled:bg-gray-400');
  btnSubmit.classList.remove('disabled:cursor-not-allowed');
  btnSubmit.classList.remove('disabled:shadow-none');
  btnSubmit.classList.remove('cursor-not-allowed');
}

const disableButtonForm = () => {
  const btnSubmit = document.getElementById('btn-submit');
  btnSubmit.setAttribute('disabled', 'true');
  btnSubmit.classList.add('disabled:bg-gray-400');
  btnSubmit.classList.add('disabled:cursor-not-allowed');
  btnSubmit.classList.add('disabled:shadow-none');
  btnSubmit.classList.add('cursor-not-allowed');
}

const checkEmail = () => {
  partyGuestsStore.checkEmail()
    .then(response => {
      if (!response.data.action) {
        toast.error(response.data.message);
        disableButtonForm();
      }
    })
}
</script>