class Utils {

  static fadeOut(element) {
    return new Promise((resolve) => {
      let el = document.getElementById(element);
      el.style.opacity = 1;
      let interval = setInterval(function() {
        let opacity = parseFloat(el.style.opacity);
        if (opacity > 0) {
          opacity -= 0.1;
          el.style.opacity = opacity;
        } else {
          el.classList.add('hidden');
          clearInterval(interval);
          resolve(); // Resolve a Promise quando a opacidade chega a 0
        }
      }, 50);
    });
  }

  static fadeIn(element) {
    return new Promise((resolve) => {
      let el = document.getElementById(element);
      el.style.opacity = 0;
      el.classList.remove('hidden');
      let interval = setInterval(function() {
        let opacity = parseFloat(el.style.opacity);
        if (opacity < 1) {
          opacity += 0.1;
          el.style.opacity = opacity;
        } else {
          clearInterval(interval);
          resolve(); // Resolve a Promise quando a opacidade chega a 1
        }
      }, 50);
    });
  }
  static validaCpf(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
      !cpf ||
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
    ) {
      return false
    }
    let soma = 0
    let resto
    for (let i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(9, 10)) ) return false
    soma = 0
    for (let i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(10, 11) ) ) return false
    return true
  }

  static isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  static downloadResource(resource, filename) {
    let base64String = resource;
    let fileName = filename;

    // Converte a string base64 para um array de bytes
    let byteCharacters = atob(base64String);

    // Cria um array de bytes vazio para armazenar os dados do arquivo
    let byteArray = new Uint8Array(byteCharacters.length);

    // Preenche o array de bytes com os dados do arquivo
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }

    // Cria um Blob a partir do array de bytes
    let blob = new Blob([byteArray], { type: 'application/octet-stream' });

    // Cria um URL temporário para o Blob
    let url = window.URL.createObjectURL(blob);

    // Cria um link para fazer o download do arquivo
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName; // Define o nome do arquivo
    document.body.appendChild(a);
    a.click();

    // Libera o URL temporário
    window.URL.revokeObjectURL(url);
  }

}

export default Utils