import { defineStore, acceptHMRUpdate } from "pinia"
import axios from 'axios'

export const useBracelets = defineStore({
  id: 'bracelets',
  state: () => ({
    isLoadingBracelets: false,
    bracelet: {
      id: '',
      color: '',
      status_bracelet: ''
    },
    bracelets: [],
  }),
  actions: {
    async getBracelets() {
      try {
        return await axios.get('/bracelets/get');
      } catch (error) {
        throw error;
      }
    },
    setBracelets(bracelets) {
      this.bracelets = bracelets;
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBracelets, import.meta.hot))
}